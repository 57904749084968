// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/fonts/Apple.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/fonts/pixel_invaders.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../public/fonts/Minitel.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../../public/fonts/material-design-icons/material.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: \"apple\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n}\n@font-face {\n    font-family: \"invaders\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n}\n@font-face {\n    font-family: \"minitel\";\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");\n}\n@font-face {\n    font-family: \"material-icons\";\n    font-style: normal;\n    font-weight: normal;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n\n.material-icons {\n    font-family: material-icons;\n}\n.invaders {\n    font-family: invaders;\n}\n.minitel {\n    font-family: minitel;\n}\n.apple {\n    font-family: apple;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/fonts.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,+DAA2D;AAC/D;AACA;IACI,uBAAuB;IACvB,+DAAoE;AACxE;AACA;IACI,sBAAsB;IACtB,+DAA6D;AACjE;AACA;IACI,6BAA6B;IAC7B,kBAAkB;IAClB,mBAAmB;IACnB,4CAAiE;AACrE;;AAEA;IACI,2BAA2B;AAC/B;AACA;IACI,qBAAqB;AACzB;AACA;IACI,oBAAoB;AACxB;AACA;IACI,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: \"apple\";\n    src: url(\"../../public/fonts/Apple.ttf\") format(\"truetype\");\n}\n@font-face {\n    font-family: \"invaders\";\n    src: url(\"../../public/fonts/pixel_invaders.ttf\") format(\"truetype\");\n}\n@font-face {\n    font-family: \"minitel\";\n    src: url(\"../../public/fonts/Minitel.ttf\") format(\"truetype\");\n}\n@font-face {\n    font-family: \"material-icons\";\n    font-style: normal;\n    font-weight: normal;\n    src: url(\"../../public/fonts/material-design-icons/material.ttf\");\n}\n\n.material-icons {\n    font-family: material-icons;\n}\n.invaders {\n    font-family: invaders;\n}\n.minitel {\n    font-family: minitel;\n}\n.apple {\n    font-family: apple;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
